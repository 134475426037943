<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    testimonialData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  components: {
    Carousel,
    Slide,
  },
};
</script>

<template>
  <div>
    <div class="window">
      <carousel id="customer-testi" :per-page="3" dir="ltr" :autoplay="true" :loop="true">
        <Slide v-for="item in testimonialData" :key="item.id">
          <div class="media customer-testi m-2">
            <img width="45" height="45" :src="`${item.profile}`" class="avatar mr-3 rounded shadow" alt="" />
            <div class="media-body content p-3 shadow rounded bg-white position-relative">
             <div class="list-unstyled mb-0">
               <el-rate :value="item.rate" disabled></el-rate>
             </div>
              <p class="text-muted mt-2"><span v-html="item.message"></span></p>
              <h6 class="text-primary">
                - {{ item.name }}
                <small class="text-muted">{{ item.designation }}</small>
              </h6>
            </div>
          </div>
        </Slide>
      </carousel>
    </div>
    <div v-for="item in testimonialData" :key="item.id" class="media m-2 phone">
      <img :src="`${item.profile}`" class="avatar avatar-small mr-3 rounded shadow mb-2 mt-5" alt="" />
      <div class="media-body content p-3 shadow rounded bg-white position-relative">
        <div class="list-unstyled mb-0">
          <el-rate :value="item.rate" disabled></el-rate>
        </div>
        <p class="text-muted mt-2"><span v-html="item.message"></span></p>
        <h6 class="text-primary">
          - {{ item.name }}
          <small class="text-muted">{{ item.designation }}</small>
        </h6>
      </div>
    </div>
  </div>
</template>