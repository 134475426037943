<script>
import {
  Carousel,
  Slide
} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";


import storage from '@/storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";


import Navbar from "@/views/landing/navbar";
import Footer from "@/views/landing/footerBar";

/**
 * Index-1
 */
export default {
  metaInfo: {
    title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/policy-a' }
    ]
  },
  data() {
    return {
      data: [],
      login: false
    }
  },
  components: {
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Navbar,
  },
  created() {
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    } else {

    }
  }
};
</script>

<template>
  <div>
    <Navbar v-if="!login"/>
    <Navbar v-else/>


    <!-- 顶部 -->
    <section class="bg-half-170 d-table w-100 pb-0" style="padding-top: 100px;">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Privacy Policy</h4>
            <!--            <p class="text-muted mx-auto mb-0">-->
            <!--              If you could not find an answer to your questions,please contant our customer support-->
            <!--            </p>-->
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <p>In the Privacy Policy, IncCrypto collects, uses, shares and protects user information received through the website inccrypto.com.Any personal information that we collect from users (you, your or customer) or that you provide to us will be processed by us.By "personal information" we mean any data that, either separately or in combination with other information, allows us to directly or indirectly identify, for example, your name, email address, IP address, device identifier or other online identifier.IncCrypto, uses the information collected about you solely to fulfill its contractual obligations and improve customer service.In turn, IncCrypto are committed to making every effort to protect your privacy.The Privacy Policy together with the Terms of Use and other documents to which it refers defines the principles and rules on the basis of which we collect, process and use your personal data.Please read this document carefully in order to understand our position and policies regarding your personal data and how we will use it. If you do not want your personal information to be collected, you need to stop working on this site.</p>
        <strong style="font-size:22px;">
          1. USER INFORMATION&nbsp;
        </strong><br/>
        <p>Information that you provide to us during registration.When you create an account, at IncCrypto, you provide us personal information that includes your contact information (email address and password).Each time you visit our site and use our services, we can automatically collect the following information, which may be considered personal information in combination with other information about you:technical information (including the Internet Protocol (IP) address used to connect your computer or other gadge), your login details, browser type and version, browser plug-in types and versions, operating system and platform.information about your visit to the site (including the dates and times during which you use the time you visited the site on certain pages), information about the interaction of the pages, page response time or data loading errors.information about your location. During downloading or using our services, we can collect real-time location information from your device at any time. This information is used to optimize our services.We do not collect any special categories of personal information about you (such as information about your race or ethnicity, religious or philosophical beliefs, sexual orientation, political opinions).</p>

        <strong style="font-size:22px;">
          2. HOW DO WE USE YOUR PERSONAL DATA
        </strong>
        <p>The information, provided to us can be used for:verification and authentication of the identity of users, that open and manage accounts in order to prevent fraud, deception and other illegal actions.compliance with legal requirements regarding the legalization (laundering) of proceeds from crime, the financing of terrorism.informing you about changes related to our services.administering site and conducting internal operations, including troubleshooting, data analysis, testing, collecting statistical data and for conducting surveys.ensure the security of our site, our services, as well as your accounts.providing support, prompt response and resolution for your complaints, questions related to the use of our services and the capabilities of our website.It is important that your personal information that we adhere to is accurate and up to date.In the occasion of any changes to your personal information, you must immediately correct your data or inform us for correction.</p>

        <strong style="font-size:22px;">
          3. USE OF COOKIES
        </strong>
        <p>We use the IP address and unique identifiers stored in the cookies of your device to help us identify your identity and provide our service. We may also use cookies stored in emails. The information collected from cookies is used by us to evaluate the effectiveness of our website, identify problems that may arise when accessing our website, and administer the platform. We use the information collected to protect our site, user accounts. We use IP addresses and cookies to protect against automatic abuse, such as spam, phishing and distributed denial of service (DDoS) attacks. Given our legal obligations and system requirements, we cannot provide you services without data such as identification, contact information and transaction information.</p>

        <strong style="font-size:22px;">
          4. DISCLOSURE OF YOUR PERSONAL DATA
        </strong>
        <p>You agree that we have the right to share your personal information with any member of our business company, which may include our subsidiaries, parent companies and subcontractors.We may transfer your personal data to third parties, including:business partners, suppliers and subcontractors, in order to fulfill obligations under contracts that are concluded with them or with you.providers of analytical data and search, which help us improve and optimize the operation of our site.</p>
        <p>We may also disclose your personal data to third parties in the following cases:if IncCrypto or almost all of its assets are acquired by a third party, in this case the personal information held by it in relation to its customers will be one of the transferred assets.if we are required to disclose or transmit the personal data of the user in accordance with any applicable law or in the implementation of court decisions; in case it is necessary to ensure the protection of their rights, their property, guarantee the security of #, our users or other persons. This includes exchanging information with companies and organizations to prevent fraud.We do not disclose or provide any personal information to third-party sources without reviewing our legal team and / or prior consent of the user.</p>

        <strong style="font-size:22px;">
          5. SECURITY AND STORAGE OF PERSONAL DATA
        </strong>
        <p>We apply security measures to ensure the confidentiality of your personal data and protect them from loss, misuse, alteration or destruction. Only authorized personnel IncCrypto has access to your personal data, and these employees are required to treat this information as confidential.By giving us your personal data, you consent to the transfer, storage and processing of your personal data. In turn, we will take all necessary measures to ensure that your personal data is processed in compliance with the security rules and in accordance with the provisions of this Privacy Policy.Unfortunately, the transmission of information via the Internet cannot be completely secureWe cannot guarantee the security of your data transmitted to us by e-mail or through our website. In this regard, you are solely responsible for possible failures in the transfer of your data to the site.Having received your personal data, we undertake to take all necessary technical measures to protect against the prevention of unauthorized access.</p>

        <strong style="font-size:22px;">
          6. REASONS FOR THE PROCESSING OF PERSONAL DATA
        </strong>
        <p>We will take all necessary measures to ensure the reliable processing of your data in accordance with this Privacy Policy. All data that you provide to us is stored on our secure servers.We only collect and process your personal data when we have legal grounds for such processing.</p>

        <strong style="font-size:22px;">
          7. USER RIGHTS
        </strong>
        <p>Users have rights regarding personal information.They are consist of:the right to delete your data (all or partly).the right to change or correct personal data, in particular when they are incorrectly indicated.the right to protest or restrict the processing of your data.the right to prohibit the use of your personal data for marketing purposes.Please note that the exercise of some of your rights, depending on the situation, may limit, complicate or completely exclude the possibility of further cooperation.</p>

        <strong style="font-size:22px;">
          8. PROTECTION OF PERSONAL DATA OF USERS.
        </strong>
        <p>This Privacy Policy and the use of your personal information applies only to the information you provide. You are solely responsible for the disclosure of personal information while using the Internet. For example through chat rooms, communities, or other publicly accessible online forums where this information may be collected and used by third parties.You are solely responsible for viewing the privacy statements, policies, terms and conditions of any person or company to whom you want to establish a link or with whom you want to enter into an agreement. IncCrypto is not responsible for the privacy statements, policies, conditions or other content of any site not belonging to IncCrypto.</p>

        <strong style="font-size:22px;">
          9. CHANGES TO THE PRIVACY POLICY
        </strong>
        <p>All changes made by IncCrypto to the Privacy Policy will be published on this page. If the Privacy Policy is significantly modified, users will be notified in writing by email.Please regularly review the information in order to familiarize yourself with updates or changes to our Privacy Policy.Such changes are published on our website and take effect immediately after publication.</p>


      </div>
    </section>



    <Footer/>
    <!-- Footer End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
       v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
